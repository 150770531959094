import React, { ReactElement } from "react";
import Box, {BoxProps} from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text";
import LabelContent from "@onnit-js/ui/components/module/LabelContent";

interface Props extends BoxProps {
    label?: ReactElement;
}

const AllYou = ({ label, ...rest }: Props) => {
    return (
        <Box py={[4]} {...rest}>
            <Text
                as="h2"
                textAlign="center"
                fontSize={["48px", "64px", "100px", "124px"]}
                fontWeight="black"
                fontStyle="italic"
                textTransform="uppercase"
                letterSpacing={2}
                lineHeight={1}
                px={5}
                my={[6, 8]}
            >
                All You
                <sup style={{ fontSize: "0.25em", verticalAlign: "top", lineHeight: "1.35em" }}>&trade;</sup>
            </Text>
            <LabelContent
                withDash={true}
                dashColor="sunwashGold"
                label={label ? label : undefined}
                content={
                    <Text as="p" typeStyle="copyHero02" color="gray">
                        At Onnit, we measure time as moments that take you closer to who you want to become. Improvement
                        in one area, specifically, will bring improvements universally, while a deficiency in anything
                        will hold back everything. Therefore, time spent bringing up one aspect of your mind, body, or
                        spirit today makes everything a little bit better than it was yesterday. Ultimately, the only
                        way to get to the best version of you is you... Are you up to it?
                    </Text>
                }
            />
        </Box>
    );
};

export default AllYou;
