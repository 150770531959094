import React from "react";
import Media from "@onnit-js/ui/components/media";
import ThemeProvider from '@onnit-js/ui/components/themes/ThemeProvider';
import theme from "@onnit-js/ui/components/themes/light";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Text, {MultiColumnText} from "@onnit-js/ui/components/text";
import {Img} from "@onnit-js/ui/components/image";
import Box from "@onnit-js/ui/components/box/Box";
import LabelContent from "@onnit-js/ui/components/module/LabelContent";
import FixedAsideContent from "@onnit-js/ui/components/module/FixedAsideContent";
import MediaCollage01 from "@onnit-js/ui/components/module/MediaCollage01";
import MediaCollage02 from "@onnit-js/ui/components/module/MediaCollage02";
import MediaCollage03 from "@onnit-js/ui/components/module/MediaCollage03";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import ImgLandscape01 from "@onnit-js/ui/components/module/ImgLandscape01";
import ImgDualPortrait from "@onnit-js/ui/components/module/ImgDualPortrait";
import Embed from "@onnit-js/ui/components/video/Embed";
import Video from "@onnit-js/ui/components/video/Video";
import BgImageGradient from "@onnit-js/ui/components/module/BgImageGradient";
import CopyDisplay02 from "@onnit-js/ui/components/module/CopyDisplay02";
import MediaPano from "@onnit-js/ui/components/module/MediaPano";
import LeftLineCTA from "@onnit-js/ui/components/cta/LeftLineCTA";
import MediaBg02 from "@onnit-js/ui/components/module/MediaBg02";
import MediaHero01 from "@onnit-js/ui/components/module/MediaHero01";
import MediaHero02 from "@onnit-js/ui/components/module/MediaHero02";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import Dash from "@onnit-js/ui/components/icon/Dash";
import HeadlineLabelContent from "@onnit-js/ui/components/module/HeadlineLabelContent";
import MediaShowcase02 from "@onnit-js/ui/components/module/MediaShowcase02";
import AllYou from "../../components/common/AllYou";
import Sup from "@onnit-js/ui/components/text/Sup";

function Page() {
    const onnitContext = useOnnitContext();

    if (!onnitContext) return null;

    const IMG_URL = onnitContext.url.cdn.static_images + "/landers/what-is-onnit-v2";
    const VID_URL = onnitContext.url.cdn.static + "/images/landers/what-is-onnit-v2";

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Text as="h1" className="sr-only">
                    What Is Onnit?
                </Text>
                <Embed title="What is Onnit Video" src="https://player.vimeo.com/video/383337484" />
                <Box>
                    <AllYou
                        my={[0, 6]}
                        label={
                            <Text typeStyle="copy05" color="black">
                                Brand Belief
                            </Text>
                        }
                    />

                    <MediaCollage01
                        pt={[4, 6, 8]}
                        pb={["12%"]}
                        item1={
                            <Img
                                src={`${IMG_URL}/image_28.jpg?q=40`}
                                alt="Woman holding kettlebell"
                                width={448}
                                height={692}
                            />
                        }
                        item2={
                            <Img
                                src={`${IMG_URL}/image_27.jpg?q=40`}
                                alt="Man standing near gym equipment"
                                width={448}
                                height={690}
                            />
                        }
                        item3={
                            <Img src={`${IMG_URL}/image_26.jpg?q=40`} alt="Woman running" width={928} height={1288} />
                        }
                    />

                    <Box bg="grays.7" pb="15%">
                        {/* transform in on the whole component so children's position stay relative to each other.  */}
                        <ImageContentPortrait
                            transform={["translateY(-6%)", "translateY(-6%)", "translateY(-12%)"]}
                            image={
                                <Img
                                    src={`${IMG_URL}/image_32.jpg?q=40`}
                                    alt="Girl doing a yoga pose"
                                    width={618}
                                    height={840}
                                />
                            }
                            content={
                                <Box pt={[0, 0, 10]}>
                                    <Text as="h3" color="sunwashGold" typeStyle="copyDisplay02" mb={[4, 4, 6]}>
                                        Where We <br />
                                        Want To Go
                                    </Text>
                                    <Text color="white" as="p" typeStyle="copyHero02">
                                        We are on a mission to empower as many individuals as possible with a holistic
                                        philosophy regarding physical, mental, and spiritual well-being. We believe that
                                        if people have access to the best tools and knowledge, they will be able to
                                        achieve their fullest human potential, leading to a global movement for the good
                                        of all.
                                    </Text>
                                </Box>
                            }
                        />
                        <ImgLandscape01 gridColumn="4 / span 8" px={[0, 0, 6]}>
                            <Img
                                src={`${IMG_URL}/image_23.jpg?q=40`}
                                alt="Skier in mountains"
                                width={1888}
                                height={1062}
                            />
                        </ImgLandscape01>

                        <LabelContent
                            py={8}
                            label={
                                <Text typeStyle="copy05" color="sunwashGold">
                                    Brand Belief
                                </Text>
                            }
                            content={
                                <>
                                    <Text as="h3" color="sunwashGold" typeStyle="copyDisplay02" mb={[4, 6]}>
                                        What We Believe
                                    </Text>
                                    <MultiColumnText>
                                        <Text as="p" typeStyle="copyHero02" color="white" mb={4}>
                                            Whether you are like one of the elite performers found on our Pro Team, or
                                            just an everyday sort of hero looking to make the most of life, Onnit’s
                                            pledge is to help you be the most fully-developed person you can be—in your
                                            body and in your life.
                                        </Text>
                                        <Text as="p" typeStyle="copyHero02" color="white" mb={4}>
                                            You’ve probably landed on this website armed with one or more questions
                                            about health, fitness, nutrition, and mindset: “How can I lose weight/stay
                                            healthy/get strong?” “What should I do with kettlebells?” “How do I practice
                                            meditation?” From the basics of working out and eating right to the complex
                                            avenues of mental and emotional development, we’re on it (hence, Onnit).
                                        </Text>
                                        <Text as="p" typeStyle="copyHero02" color="white" mb={4}>
                                            Onnit can be summarized in three words: total human optimization (THO). As
                                            our founder Aubrey Marcus likes to say, “THO is a commitment to get strong
                                            in the places where we are weak, become great in places where we’re good,
                                            and shine a light on the stuff we need to take a look at. Most importantly,
                                            THO is about recognizing that nothing works in a vacuum. You have to bring
                                            it all together if you want to maximize what’s possible.”
                                        </Text>
                                    </MultiColumnText>
                                </>
                            }
                        />
                    </Box>

                    <ImgDualPortrait mt={["-30%", "-15%"]} mb={8}>
                        <Img src={`${IMG_URL}/image_25.jpg?q=40`} alt="Image of Gamer" width={928} height={928} />
                        <Img
                            src={`${IMG_URL}/image_24.jpg?q=40`}
                            alt="Athlete preparing for the big game"
                            width={928}
                            height={1310}
                        />
                    </ImgDualPortrait>

                    <LabelContent
                        label={
                            <Text typeStyle="copy05" color="black">
                                Brand Belief
                            </Text>
                        }
                        content={
                            <>
                                <Text as="h3" color="black" typeStyle="copyDisplay02" mb={[4, 6]}>
                                    What is the purpose of <Box as="span" display={["inline", "block"]} />
                                    Total Human Optimization
                                </Text>

                                <Text as="p" typeStyle="copyHero02" color="gray" mb={[4, 4, 6]}>
                                    Optimization is a process and not a prize. A journey—not a destination. To be truly
                                    optimized is to live up to your potential at any given moment. At the same time, we
                                    recognize that your potential today is not the same as it will be in a year if you
                                    stay the course, so to judge yourself today on the standards of tomorrow is
                                    foolhardy. To start optimizing effectively now, you only have to recognize that the
                                    mind, body, and spirit are interconnected.
                                </Text>
                            </>
                        }
                    />

                    <ImgLandscape01 gridColumn="4 / span 8" px={[0, 0, 5]}>
                        <Img src={`${IMG_URL}/image_22.jpg?q=40`} alt="Athlete smiling" width={1888} height={1062} />
                    </ImgLandscape01>

                    <Box
                        display="grid"
                        maxWidth="1920px"
                        gridTemplateColumns="repeat(12, 1fr)"
                        mx="auto"
                        my={6}
                        px={4}
                        py={6}
                    >
                        <Box gridColumn={["1 / -1", "2 / 12"]}>
                            <Text typeStyle="copyDisplay01" color="black">
                                "Improvement in one area, specifically, will bring improvements universally, while a
                                deficiency in anything will hold back everything."
                            </Text>
                        </Box>
                    </Box>

                    <Media query="(min-width: 640px)">
                        <MediaPano mt={[0, 0, "8%"]} mb={[0, "-24%", "-8%"]} zIndex={1}>
                            <Video playsInline autoPlay muted loop>
                                <source src={`${VID_URL}/mountains.mp4`} type="video/mp4" />
                            </Video>
                        </MediaPano>
                    </Media>

                    <BgImageGradient bgSrc={`${IMG_URL}/utahParks.jpg`} pt={[6, "24%", "12%"]}>
                        <CopyDisplay02 dashColor="earthGreen" py={[5, 5, 8]}>
                            <Text as="h3" color="white" typeStyle="copyDisplay02" mb={2}>
                                A Unique Approach <Box as="span" display={["inline", "inline", "block"]} />
                                To Supplementation
                            </Text>
                            <LeftLineCTA href="/" color="white">
                                See our best products
                            </LeftLineCTA>

                            <MultiColumnText mt={6}>
                                <Text as="p" typeStyle="copyHero02" color="white">
                                    Ready to begin your journey to Total Human Optimization? The best place to start is
                                    with our{" "}
                                    <a href="/total-human" style={{ textDecoration: "underline" }}>
                                        Total Human
                                    </a>
                                    <Sup variant="reg" /> product. Convenient day and night packs combine all of the
                                    best of our human performance supplements to offer nutrition that goes light years
                                    beyond a traditional multivitamin. From there, feel free to explore the different
                                    unique combinations that we’ve tried to make as simple as possible to understand.
                                </Text>
                            </MultiColumnText>
                        </CopyDisplay02>
                    </BgImageGradient>

                    <Box bg="grays.8">
                        <ImgLandscape01 gridColumn="3 / span 8" px={[0, 0, 5]} pb={[5, 5, 8]}>
                            <Img
                                src={`${IMG_URL}/alpha-brain.jpg?q=40`}
                                alt="Alpha BRAIN® surrounded by items"
                                width={2644}
                                height={1893}
                            />
                        </ImgLandscape01>

                        <ImageContentPortrait
                            py={[5, 5, 8]}
                            image={
                                <Video playsInline autoPlay muted loop placeholder={[380, 380]}>
                                    <source src={`${VID_URL}/products.mp4`} type="video/mp4" />
                                </Video>
                            }
                            content={
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text as="p" typeStyle="copyHero02" color="white" mb={4}>
                                        We believe that nutritional supplements can help almost anyone optimize. We
                                        built our reputation as the best in class in the supplement industry by
                                        combining efficacious ingredients backed by rigorous clinical research.
                                    </Text>
                                </Box>
                            }
                        />

                        <ImageContentPortrait
                            reverse={true}
                            py={[5, 5, 8]}
                            image={
                                <Img
                                    src={`${IMG_URL}/image_20.jpg?q=40`}
                                    alt="Bottle of New MOOD&reg; surrounded by ingredients"
                                    width={928}
                                    height={928}
                                />
                            }
                            content={
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text as="p" typeStyle="copyHero02" color="white" mb={4}>
                                        Our supplements are sourced from the best ingredients from all over the world,
                                        with the intent to create something that supports the body in all of its
                                        efforts.
                                    </Text>
                                </Box>
                            }
                        />
                    </Box>

                    <MediaBg02
                        backgroundColor="black"
                        backgroundImage={`${IMG_URL}/image_18.jpg?q=40`}
                        headline={
                            <Text as="h2" typeStyle="headlineHero01" color="white">
                                Earth <br />
                                Grown <br />
                                Nutrients
                            </Text>
                        }
                        content={
                            <Text as="p" typeStyle="copyHero02" color="grays.4">
                                You know the expression “you are what you eat.” Most diets and eating philosophies focus
                                on one ambition—quick weight loss, maximum muscle gain, optimal health, greater energy,
                                or ecological sustainability. We advocate an approach that serves them all, whether you
                                personally prefer to eat low-carb, keto, Paleo, vegan, or any other way. Our blog
                                articles can show you how to achieve a better body, higher performance, and lasting
                                health with a diet that’s based on whole foods, and sourced from farms that both honor
                                the planet and treat animals humanely.
                            </Text>
                        }
                    />

                    <Box bg="gameRed" py={[5, 8, 10]}>
                        <ImageContentPortrait
                            mb={[5, 8, 10]}
                            reverse={true}
                            image={
                                <Img
                                    src={`${IMG_URL}/image_19.jpg?q=40`}
                                    alt="Nut butter spread over bread slices"
                                    width={928}
                                    height={928}
                                />
                            }
                            content={
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text as="p" typeStyle="copyHero02" color="white" mb={5}>
                                        While we don’t turn a blind eye to any nutrition viewpoint and are always
                                        scanning the latest information, Onnit is a place where all diets and lifestyles
                                        can intersect.
                                    </Text>
                                    <LeftLineCTA href="/academy/recipes" color="white">
                                        See our recipes
                                    </LeftLineCTA>
                                </Box>
                            }
                        />
                        <ImageContentPortrait
                            image={
                                <Video playsInline autoPlay muted loop placeholder={[380, 380]}>
                                    <source src={`${VID_URL}/foods.mp4`} type="video/mp4" />
                                </Video>
                            }
                            content={
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text as="p" typeStyle="copyHero02" color="white" mb={5}>
                                        Onnit’s foods and supplements go out to our customers as close to how we found
                                        them in nature as possible.
                                    </Text>
                                    <LeftLineCTA href="/academy/category/nutrition" color="white">
                                        See our content
                                    </LeftLineCTA>
                                </Box>
                            }
                        />
                    </Box>

                    <Box pt={[6, 6, 8, 10]} mb="-160px">
                        <FixedAsideContent
                            mb={[6, 8, 9]}
                            reverse={true}
                            showAsideOnMobile={true}
                            aside={
                                <>
                                    <Text typeStyle="copy01" color="grays.5">
                                        Certification
                                    </Text>
                                    <Text typeStyle="copy02" color="grays.4">
                                        Rainforest Alliance
                                        <br />
                                        UTZ
                                    </Text>
                                </>
                            }
                            content={
                                <Text typeStyle="copyHero02" color="grays.4">
                                    Many of our products proudly bear certifications from organizations such as the
                                    Rainforest Alliance and UTZ, and are continually third-party tested for purity, as
                                    well as banned substances, in an effort to ensure that Onnit products are
                                    environmentally friendly and safe for use among competitive athletes who can’t
                                    afford to fail a drug test.
                                </Text>
                            }
                        />
                        <ImgLandscape01 gridColumn="4 / span 8" pb={6}>
                            <Img
                                src={`${IMG_URL}/image_16.jpg?q=40`}
                                alt="Man doing kettlebell lunge"
                                width={1888}
                                height={1062}
                            />
                        </ImgLandscape01>
                    </Box>
                    <Box bg="grays.8" pt={["184px", "26%", "260px"]} pb={[6, 8, 10]}>
                        <MediaHero01
                            mb={[6, 6, 8]}
                            label={
                                <Text typeStyle="copy05" color="white">
                                    What is Onnit Fitness
                                </Text>
                            }
                            media={
                                <Video playsInline autoPlay muted loop placeholder={[380, 380]}>
                                    <source src={`${VID_URL}/tools.mp4`} type="video/mp4" />
                                </Video>
                            }
                            cta={
                                <LeftLineCTA href="/fitness" color="white">
                                    See our fitness equipment
                                </LeftLineCTA>
                            }
                            headline={
                                <Text as="h2">
                                    <Text typeStyle="headlineHero01" color="trainingBlue">
                                        Unique{" "}
                                    </Text>
                                    <Text typeStyle="headlineHero01" color="trainingBlue">
                                        Fitness{" "}
                                    </Text>
                                    <Text typeStyle="headlineHero01" color="trainingBlue">
                                        Tools{" "}
                                    </Text>
                                </Text>
                            }
                        />

                        <Grid>
                            <Box gridColumn={["1 / -1", "1 / -1", "3 / span 8"]} px={5}>
                                <Text typeStyle="copyHero02" color="white">
                                    On the fitness front, Onnit specializes in what our colleagues have dubbed
                                    “unconventional training”—employing equipment and methods that aren’t often found in
                                    the typical commercial gym. While they may be new to you, many of these
                                    unconventional training ideas have been around for centuries, and were practiced by
                                    warriors from ancient Greece, Rome, and Persia.
                                </Text>
                            </Box>
                        </Grid>
                    </Box>

                    <ImageContentPortrait
                        pt={[5, 8, 10]}
                        image={
                            <Img
                                src={`${IMG_URL}/image_15.jpg?q=40`}
                                alt="Woman using steel mace"
                                width={928}
                                height={1200}
                            />
                        }
                        content={
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Dash color="trainingBlue" mb={5} />
                                <Text as="p" typeStyle="copyHero02">
                                    Onnit’s unconventional tools—including but not limited to the kettlebell, steel
                                    mace, steel club, battle ropes, and sandbag—challenge the body in unique ways,
                                    helping to improve total-body stability and core strength, as well as rev up the
                                    metabolism.
                                </Text>
                            </Box>
                        }
                    />

                    <Box display={["block", "grid"]} gridTemplateColumns="1fr 4fr 3fr">
                        <Box gridColumn={["1 / -1", "2 / span 1"]} px={3}>
                            <MediaShowcase02
                                bgElement={
                                    <Video autoPlay playsInline muted loop placeholder={[824, 228]}>
                                        <source src={`${VID_URL}/fitness.mp4`} type="video/mp4" />
                                    </Video>
                                }
                            >
                                <img src={`${IMG_URL}/slider-mace.png?q=40`} alt="Steel Mace" />
                                <img src={`${IMG_URL}/slider-kettlebell.png?q=40`} alt="Kettlebell" />
                                <img src={`${IMG_URL}/slider-club.png?q=40`} alt="Club" />
                            </MediaShowcase02>
                        </Box>
                    </Box>

                    <Box bg="grays.8" mt={["-52%", "-26%"]} pt={["40%", "16%"]}>
                        <Grid pb={6}>
                            <Box gridColumn={["1 / -1", "6 / span 6"]} p={[4, 5]}>
                                <Text typeStyle="copyHero02" color="white" mb={5}>
                                    Unconventional training can serve as a supplement to—and sometimes even a
                                    replacement for—barbells, dumbbells, and machines. It’s the heart of the training
                                    done at the Onnit Gym in Austin, TX, which is rapidly becoming a destination for
                                    elite athletes and celebrities around the world.
                                </Text>
                                <LeftLineCTA href="https://www.onnitgym.com" color="white">
                                    See the Onnit Gym
                                </LeftLineCTA>
                            </Box>
                        </Grid>
                        <MediaCollage02
                            mt={[0, 0, 8]}
                            item1={
                                <Img
                                    src={`${IMG_URL}/image_9.jpg?q=40`}
                                    alt="Athlete pulling ropes"
                                    width={488}
                                    height={336}
                                />
                            }
                            item2={
                                <Img
                                    src={`${IMG_URL}/image_8.jpg?q=40`}
                                    alt="Athlete using steel club"
                                    width={688}
                                    height={688}
                                />
                            }
                            item3={
                                <Img
                                    src={`${IMG_URL}/image_10.jpg?q=40`}
                                    alt="Athlete using kettlebell"
                                    width={1168}
                                    height={876}
                                />
                            }
                        />
                    </Box>

                    <Box>
                        <LabelContent
                            bg="white"
                            mt={["-25%", "-18%"]}
                            pt={["40%", "22%"]}
                            pb={[5, 8, 8, 10]}
                            label={<Text typeStyle="copy05">Training</Text>}
                            content={
                                <>
                                    <Text
                                        as="h3"
                                        color="trainingBlue"
                                        typeStyle="headlineHero01"
                                        mb={[4, 4, 6]}
                                        fontSize={[8, 10, 12, 14]}
                                    >
                                        Mindset is Everything
                                    </Text>
                                    <MultiColumnText>
                                        <Text as="p" typeStyle="copyHero02" mb={4}>
                                            Whether your ultimate goal is to optimize your body, health, relationships,
                                            career, or anything else, you have to get your head on straight. That means
                                            adopting habits that train your mind to be open, focused, and calm. From
                                            learning to breathe like Dutch daredevil Wim Hof to living a life without
                                            regrets with tips from an end-of-life caregiver, we have strategies to help
                                            mitigate stress and develop self-awareness and emotional balance. While many
                                            websites can show you the steps to achieving goals—leaving you with a plan
                                            your unconscious mind won’t let you follow—ours seeks to teach you how to
                                            create the mindset that makes anything you want achievable.
                                        </Text>
                                    </MultiColumnText>
                                </>
                            }
                        />
                    </Box>

                    <Box bg="grays.7" py={[6, 6, 8]}>
                        <MediaHero02
                            media={
                                <Video playsInline autoPlay muted loop placeholder={[824, 228]}>
                                    <source src={`${VID_URL}/austin.mp4`} type="video/mp4" />
                                </Video>
                            }
                            headline={
                                <Text as="h2">
                                    <Text typeStyle="headlineHero01" color="optimoOrange">
                                        Made In
                                    </Text>
                                    <Text typeStyle="headlineHero01" color="optimoOrange">
                                        Austin
                                    </Text>
                                </Text>
                            }
                            content={
                                <Text typeStyle="copyHero02" color="white">
                                    Onnit's roots are in Austin, TX, and we're proud to be a part of this community.
                                </Text>
                            }
                        />
                        <ImgLandscape01 gridColumn="2 / span 7" pt={8}>
                            <Img
                                src={`${IMG_URL}/image_14.jpg?q=40`}
                                alt="Athletes running"
                                width={1888}
                                height={1062}
                            />
                        </ImgLandscape01>
                    </Box>

                    <Box
                        bg="white"
                        mt={["-25%", "-20%", "-260px", "-400px"]}
                        pt={["25%", "20%", "260px", "400px"]}
                        pb={[0, "12%"]}
                    >
                        <HeadlineLabelContent
                            label={<Text typeStyle="copy05">Lifestyle</Text>}
                            headline={
                                <Text as="h3" typeStyle="headlineHero02" fontStyle="normal" color="optimoOrange">
                                    Who is Onnit?
                                </Text>
                            }
                            content={
                                <Text typeStyle="copyHero02" color="gray">
                                    You are the secret of greatness, and this is your story. History may not record
                                    these momentous first steps, but that’s okay, because they are yours. And you know
                                    they will lead you to victory, because there was never any other option.
                                </Text>
                            }
                        />

                        <Media query="(min-width: 1040px)">
                            <MediaCollage01
                                py={[8, 8, 10]}
                                item1={
                                    <Img
                                        src={`${IMG_URL}/image_6.jpg?q=40`}
                                        alt="Athletes racing"
                                        width={448}
                                        height={694}
                                    />
                                }
                                item2={
                                    <Img
                                        src={`${IMG_URL}/image_5.jpg?q=40`}
                                        alt="Athlete stretching"
                                        width={448}
                                        height={692}
                                    />
                                }
                                item3={
                                    <Img
                                        src={`${IMG_URL}/image_4.jpg?q=40`}
                                        alt="Athletes racing aerodyne bike"
                                        width={928}
                                        height={1244}
                                    />
                                }
                            />
                        </Media>

                        <MediaCollage02
                            mb={6}
                            item1={
                                <Img
                                    src={`${IMG_URL}/image_2.jpg?q=40`}
                                    alt="Gathering of people"
                                    width={448}
                                    height={332}
                                />
                            }
                            item2={
                                <Img
                                    src={`${IMG_URL}/image_1.jpg?q=40`}
                                    alt="Group of people doing kettlebell presses"
                                    width={688}
                                    height={688}
                                />
                            }
                            item3={
                                <Img
                                    src={`${IMG_URL}/image_3.jpg?q=40`}
                                    alt="Aubrey Marcus speaking"
                                    width={1168}
                                    height={874}
                                />
                            }
                        />

                        <Media query="(min-width: 1040px)">
                            <Box display={["block", "grid"]} gridTemplateColumns="repeat(12, 1fr)">
                                <Box gridColumn={["2 / span 4"]}>
                                    <Video playsInline autoPlay muted loop placeholder={[380, 380]}>
                                        <source src={`${VID_URL}/lifestyle.mp4`} type="video/mp4" />
                                    </Video>
                                </Box>
                            </Box>
                        </Media>
                    </Box>

                    <Box bg="grays.7" mt={[0, "-28%"]} pt={[0, "12%"]}>
                        <Grid py={[6, 6, 7, 9]}>
                            <Box gridColumn={["1 / -1", "1 / -1", "5 / span 6"]} p={[4, 5]}>
                                <Text as="p" typeStyle="copyHero02" color="white">
                                    We encourage the flow of ideas and sometimes we take chances, because we know it is
                                    okay to fail as long as we learn from our mistakes and correct them. We love our
                                    customers, because we ARE our customers. We live and breathe the products we create
                                    and will fight for the family of supporters that make our dream a possibility. We
                                    are Onnit, and we won’t ever stop.
                                </Text>
                            </Box>
                        </Grid>

                        <MediaCollage03
                            item1={
                                <Img
                                    src={`${IMG_URL}/image_13.jpg?q=40`}
                                    alt="Group doing yoga poses"
                                    width={1170}
                                    height={656}
                                />
                            }
                            item2={
                                <Img
                                    src={`${IMG_URL}/image_12.jpg?q=40`}
                                    alt="Man on Paddleboard"
                                    width={448}
                                    height={448}
                                />
                            }
                            item3={
                                <Img
                                    src={`${IMG_URL}/image_11.jpg?q=40`}
                                    alt="Group of people outside of Onnit's Foodtruck"
                                    width={688}
                                    height={388}
                                />
                            }
                        />

                        <Grid py={[6, 8, 9]}>
                            <Box gridColumn={["1 / -1", "3 / span 8"]} p={[4, 5]}>
                                <Text typeStyle="copyHero01" color="white" mb={[0, 6]}>
                                    The Onnit message of Total Human Optimization is universal. From world champions and
                                    elite athletes to entertainers, celebrities, and everyday people, these days, it
                                    seems everyone is getting Onnit.
                                </Text>
                            </Box>
                        </Grid>
                    </Box>

                    <Grid py={[6, 9, 10]}>
                        <Box gridColumn={["1 / -1", "1 / -1", "3 / span 8"]} p={[4, 5]}>
                            <Box mb={[6, 8, 8, 10]}>
                                <Img
                                    src={`${IMG_URL}/image_7.jpg?q=40`}
                                    alt="Joe Rogan and Aubrey Marcus on a podcast"
                                    width={1882}
                                    height={1240}
                                />
                            </Box>
                            <Img
                                width={300}
                                height={43}
                                src="https://scdn.onnit.com/images/interface/branding/onnit-logo.svg"
                                alt="Onnit"
                            />
                        </Box>
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default Page;
