import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import Box, { BoxProps } from "../box/Box";
import IndexIcon from "../shared/IndexIcon";
import useRovingIndex from "../../hooks/useRovingIndex";
import { theme } from "styled-tools";

interface Props extends BoxProps {
    children: ReactNode | ReactNode[];
    bgElement?: ReactNode;
    showSlideIndex?: boolean;
    slideIndexPosition?: "left" | "right";
}

const duration = 400;

const transitions = css`
    .item-enter {
        opacity: 0;
    }
    .item-enter-active {
        opacity: 1;
        transition: opacity ${duration}ms ease-in;
    }
    .item-exit {
        opacity: 1;
    }
    .item-exit-active {
        opacity: 0;
        transition: opacity ${duration}ms ease-in;
    }
`;

const Grid = styled(Box)`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    user-select: none;
    ${transitions};
`;

const Container = styled(Box)`
    position: relative;
    grid-column: 1 / -1;
    @media (min-width: ${p => p.theme.breakpoints[1]}) {
        grid-column: 3 / span 7;
    }
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr min-content 1fr;
`;

const Showcase = styled(Box)`
    grid-row: 1/ -1;
    grid-column: 1 / -1;
    overflow: hidden;
`;

const MediaList = styled(Box)`
    position: relative;
    display: grid;
    grid-auto-columns: 100%;
    transition: transform ${duration}ms cubic-bezier(0, 0, 0, 1);
    backface-visibility: hidden;
    z-index: 2;
`;

const MediaItem = styled(Box)<{ isActive: boolean }>`
    grid-row: 1 / span 1;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 50% !important;
        display: block;
        opacity: ${p => (p.isActive ? 1 : 0)};
        transition: opacity 750ms;
    }
`;

const BgImage = styled.div`
    grid-column: 1 / -1;
    grid-row: 2 / span 1;
    z-index: 1;
    img,
    video {
        width: 100%;
        display: block;
    }
`;

function MediaShowcase02({
    children,
    bgElement,
    showSlideIndex = true,
    slideIndexPosition = "right",
    ...rest
}: Props) {
    const total = React.Children.count(children);
    const [currentIndex, updateIndex] = useRovingIndex(total - 1);
    const showDots = showSlideIndex && total > 1;

    return (
        <Grid {...rest}>
            <Container>
                {bgElement && <BgImage>{bgElement}</BgImage>}

                <Showcase>
                    <MediaList
                        style={{
                            transform: `translateX(${currentIndex * -100}%)`
                        }}
                    >
                        {React.Children.map(children, (child, i) => (
                            <MediaItem
                                key={i}
                                gridColumn={i + 1}
                                isActive={currentIndex === i}
                            >
                                {child}
                            </MediaItem>
                        ))}
                    </MediaList>
                </Showcase>

                {showDots && (
                    <Box
                        display="flex"
                        gridRow="3"
                        gridColumn={slideIndexPosition === "right" ? -2 : 1}
                        justifyContent={
                            slideIndexPosition === "right"
                                ? "flex-end"
                                : "flex-start"
                        }
                        alignItems="flex-start"
                        py={4}
                        zIndex={10}
                    >
                        {React.Children.map(children, (child, i) => (
                            <IndexIcon
                                key={i}
                                active={i === currentIndex}
                                onClick={() => updateIndex(i)}
                                style={{ cursor: "pointer" }}
                            />
                        ))}
                    </Box>
                )}
            </Container>
        </Grid>
    );
}

export default MediaShowcase02;
